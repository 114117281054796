.flighttable tbody {
  display: block;
  /* max-height: 40vh; */
  overflow: auto;
}

.flighttable thead,
.flighttable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
